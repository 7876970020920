<template>
  <div>
    <v-row
      :key="btnKey"
      align="center"
    >
      <v-col
        v-for="(filter, index) in filters"
        :key="index"
      >
        <v-tooltip
          bottom
          color="warning"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :text="!!filter.txtBtn"

              icon
              v-bind="attrs"
              :disabled="filter.needsPharmacy && !filterStore.selectedPharmacy[0]"

              @click="handleClick(filter, index)"
              v-on="on"
            >
              <span
                v-if="filter.txtBtn"
                class="pl-3 tw-text-red-500 tw-tex-hard"
              >{{ filter.txtBtn }}</span>
              <v-icon
                v-else
                :color="filter.iconColor"
              >
                {{ filter.icon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ filter.tooltip }}</span>
        </v-tooltip>
        <v-dialog
          v-model="filter.dialog"
          :max-width="filter.maxWidth"
          :fullscreen="filter.fullscreen"
          persistent
        >
          <v-card>
            <v-card-title class="white--text primary darken-4">
              <span class="tw-text-white tw-font-bold">{{ filter.title }}</span>
              <v-spacer></v-spacer>
              <v-btn
                color="white"
                class="text--primary"
                fab
                small
                @click="filter.dialog = false"
              >
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <component
                :is="filter.component"
                :date-range-prop="filterStore.dateRange"
                :selected-pharmacy-prop="filterStore.selectedPharmacy[0]"
                :selected-molecules-prop="filterStore.selectedDrugs"
                @refreshAllData="refreshAllData"
                @resetPharmacy="resetPharmacy"
              />
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-col
                    v-for="(filter, index) in filters"
                    :key="index"
                    cols="6"
                  >
                    <v-btn
                      v-if="filter.dialog"
                      block
                      color="primary"
                      @click="filter.title === 'Pharmacy' ? resetPharmacy(index) : resetMolecule(index)"
                    >
                      <span>Reset</span>
                      <v-icon
                        right
                        class="me-2"
                      >
                        {{ icons.mdiPlaylistCheck }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    block
                    color="primary"
                    @click="refreshAllData()"
                  >
                    <span>Apply</span>
                    <v-icon
                      right
                      class="me-2"
                    >
                      {{ icons.mdiPlaylistCheck }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import {
    mdiClose, mdiTag, mdiPlaylistCheck, mdiFilterRemove
} from '@mdi/js'
import datePicker from '@/components/filters/datePicker.vue'
import { useFilterStore } from '@/stores/FilterStore'
import { usePharmacyListStore } from '@/stores/PharmacyListStore'
import { useDataStore } from '@/stores/Data'
import { filters } from '@/assets/lists'
import { checkAndExecuteDataStoreCalls } from '@/stores/checkAndExecuteDataStoreCalls'
import { useRouter } from '@core/utils'

export default {
    name: 'AppBarFilter',
    components: {
        datePicker
    },
    setup() {
        const btnKey = ref(0)
        const filterStore = useFilterStore()
        const pharmacyListStore = usePharmacyListStore()
        const dataStore = useDataStore()
        const filtersRef = ref(filters)
        const { route } = useRouter()
        const currentRoute = route.value.path
        pharmacyListStore.init()

        watch(filterStore, newVal => {
            if (filtersRef.value.length > 0) {
                filtersRef.value[0].iconColor = (newVal.getFilterQuery?.RxDate && newVal.getFilterQuery?.RxDate.length > 0) ? 'success' : 'primary'
                if (filtersRef.value[1]) { filtersRef.value[1].iconColor = (newVal.getFilterQuery?.Accreditation_Number && newVal.getFilterQuery?.Accreditation_Number.length > 0) ? 'success' : 'primary' }
                if (filtersRef.value[2]) { filtersRef.value[2].iconColor = (newVal.getFilterQuery?.genericProductNumber && newVal.getFilterQuery?.genericProductNumber.length > 0) ? 'success' : 'primary' }
            }
        }, { deep: true, immediate: true })

        return {
            btnKey,
            filterStore,
            pharmacyListStore,
            dataStore,
            filters: filtersRef,
            checkAndExecuteDataStoreCalls,
            currentRoute,
            icons: {
                mdiPlaylistCheck, mdiTag, mdiFilterRemove, mdiClose
            }
        }
    },
    mounted() { this.refreshAllData() },
    methods: {
        reset() { this.filterStore.setSelectedPharmacy([]); this.filterStore.setSelectedDrugs([]); this.refreshAllData() },
        resetPharmacy(index) { this.filterStore.setSelectedPharmacy([]); this.refreshAllData(); this.filters[index].dialog = !this.filters[index].dialog },
        resetMolecule(index) { this.filterStore.setSelectedDrugs([]); this.refreshAllData(); this.filters[index].dialog = !this.filters[index].dialog },
        async handleClick(filter, index) {
            if (filter.title === 'Reset') {
                this.filterStore.resetFilter(); this.$router.push({ name: 'home' }).catch(() => { this.refreshAllData() })

                return
            }
            if (filter.route != null) {
                this.$router.push({ name: filter.route })

                return
            }
            this.filters[index].dialog = !this.filters[index].dialog
        },
        async refreshAllData() {
            this.pharmacyListStore.loading = true
            this.checkAndExecuteDataStoreCalls(this.currentRoute, this.dataStore, this.filterStore, this.pharmacyListStore, true).catch(() => { console.error('Error executing data store calls') })
            this.pharmacyListStore.loading = false
        },
    },
}
</script>
