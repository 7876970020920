import Vue from 'vue'
import VueRouter from 'vue-router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { authGuard } from '@/auth'
import { useBreadcrumbs } from '@/stores/Breadcrumbs'
import { checkAndExecuteDataStoreCalls } from '@/stores/checkAndExecuteDataStoreCalls'
import { useFilterStore } from '@/stores/FilterStore'
import { useDataStore } from '@/stores/Data'
import { usePharmacyListStore } from '@/stores/PharmacyListStore'
import routeConfig from '@/assets/configs/routeConfig' // import routeConfig instead of routeConfig
// import { getInstance } from '@/auth/authWrapper'
import pharmacy from './routes/pharmacy'
import dashboard from './routes/dashboard'
import sos from './routes/sos'
import iqReport from './routes/iqReport'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(pinia)
Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: 'home' },
    {
        path: '/home',
        name: 'home',
        beforeEnter: authGuard,
        component: () => import('@/views/Home.vue'),
        meta: { layout: 'content', breadcrumb: 'Home' },
    },
    ...pharmacy,
    ...sos,
    ...dashboard,
    ...iqReport,
    {
        path: '/market/watchlist',
        name: 'market-watch-list',
        beforeEnter: authGuard,
        component: () => import('@/components/charts/watchList.vue'),
        meta: { layout: 'content', breadcrumb: 'Market Watch List' },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/Error404.vue'),
        meta: { layout: 'blank', resource: 'Public' },
    },
    { path: '*', redirect: 'error-404' },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
})
router.beforeEach((to, from, next) => {
    const breadcrumbs = useBreadcrumbs()
    breadcrumbs.breadcrumbs = routeConfig[to.path]?.breadcrumbs || []

    const dataStore = useDataStore()
    const filterStore = useFilterStore()
    const pharmacyListStore = usePharmacyListStore()

    checkAndExecuteDataStoreCalls(to.path, dataStore, filterStore, pharmacyListStore)
        .then(() => {
            next()
        })
        .catch(error => {
            console.log('Data store calls failed:', error)
            if (to.path !== '/home') {
                next('/home')
            } else {
                next()
            }
        })
})

export default router
