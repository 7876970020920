<template>
  <layout-content-horizontal-nav :nav-menu-items="navMenuItems">
    <!-- Default Slot -->
    <slot></slot>

    <!-- Navbar -->
    <template #navbar>
      <div
        class="navbar-content-container"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mdAndDown"
          ></v-app-bar-nav-icon>
          <router-link
            to="/"
            class="d-flex align-center text-decoration-none"
          >
            <!-- <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img> -->
            <h2 class="app-title text--primary">
              <!-- <div v-html="appName"></div> -->
              <span class="tw-text-blue-500 tw-text-base">PHOX</span><span class="tw-text-green-500 tw-text-base">IQ<span></span>
              </span>
            </h2>
          </router-link>
          <v-breadcrumbs
            v-if="$vuetify.breakpoint.mdAndUp"
            :items="breadcrumbs.breadcrumbs"
          ></v-breadcrumbs>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <FilterCard />

          <active-filter v-if="$vuetify.breakpoint.mdAndUp"></active-filter>
        </div>
        <v-spacer></v-spacer>
        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center">
          <!-- <app-bar-theme-switcher></app-bar-theme-switcher> -->
          <app-bar-user-menu class="ms-2"></app-bar-user-menu>
        </div>
      </div>

      <v-overlay
        :value="$store.state.app.shallContentShowOverlay"
        z-index="5"
        absolute
        class="system-bar-overlay"
      ></v-overlay>
    </template>

    <!-- Slot: footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
          href="https://pharmaguide.ca"
          class="text-decoration-none"
        >PharmaGuide Inc.</a><span class="d-none d-md-inline">, All rights Reserved</span></span>
        <div class="align-center d-none d-md-flex">
          <span>Hand-crafted &amp; Made with</span>
          <v-icon
            color="error"
            class="ms-2"
          >
            {{ icons.mdiHeartOutline }}
          </v-icon>
        </div>
      </div>
    </template>

    <!-- App Content -->
    <template #v-app-content>
      <app-customizer class="d-none d-md-block"></app-customizer>
    </template>
  </layout-content-horizontal-nav>
</template>

<script>
import LayoutContentHorizontalNav from '@core/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { useUserStore } from '@/stores/User'
import { sentryUser } from '@/api/sentry/sentry'

// App Bar Components
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'

import { ref, watch } from 'vue'
import themeConfig from '@themeConfig'
import { mdiHeartOutline } from '@mdi/js'
import AppBarUserMenu from '@/components/AppBar/AppBarUserMenu.vue'
import navMenuItems from '@/navigation/horizontal'
import { useBreadcrumbs } from '@/stores/Breadcrumbs'
import FilterCard from '@/components/filters/components/FilterCard.vue'
import activeFilter from '@/components/tables/activeFilter.vue'

export default {
    components: {
        FilterCard,
        activeFilter,
        LayoutContentHorizontalNav,
        AppCustomizer,
        AppBarThemeSwitcher,
        AppBarUserMenu
    },
    setup() {
        const userStore = useUserStore()
        const breadcrumbs = useBreadcrumbs()

        return {
            sentryUser,
            userStore,
            navMenuItems,
            breadcrumbs,

            // App Config
            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,

            // Icons
            icons: {
                mdiHeartOutline,
            },
        }
    },
    mounted() {
        if (process.env.NODE_ENV !== 'development') {
            sentryUser()
        }
    },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
