<template>
  <div>
    <v-card flat>
      <v-card-title>
        <span class="selected-pharmacy">
          {{ (selectedMolecules.length === 0) ? 'Please select a molecule' : '' }}
          <template v-for="(molecule, index) in selectedMolecules">
            <span
              :key="index"
              class="selected-pharmacy"
            >- {{ molecule.genericName }}</span>
            <br v-if="index < selectedMolecules.length - 1" />
          </template>
        </span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="searchText"
          outlined
          dense
          label="Search molecules..."
        />
        <v-virtual-scroll
          :items="filteredMolecules"
          :item-height="50"
          height="200"
        >
          <template #default="{ item }">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.genericName }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  depressed
                  small
                  @click="updateSelectedDrugs(item)"
                >
                  Select

                  <v-icon
                    v-if="!selectedMolecules?.some(drug => drug.genericProductNumber === item.genericProductNumber)"
                    color="orange darken-4"
                    right
                  >
                    {{ icons.mdiSelect }}
                  </v-icon>
                  <v-icon
                    v-if="selectedMolecules?.some(drug => drug.genericProductNumber === item.genericProductNumber)"
                    color="orange darken-4"
                    right
                  >
                    {{ icons.mdiSelectInverse }}
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { mdiAlertCircleOutline, mdiSelect, mdiSelectInverse } from '@mdi/js'
import { usePharmacyListStore } from '@/stores/PharmacyListStore'
import { useFilterStore } from '@/stores/FilterStore'

export default {
    name: 'MoleculeSelect',
    props: {
        selectedMoleculesProp: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        const filterStore = useFilterStore()
        const moleculeListData = ref([])
        const pharmacyListStore = usePharmacyListStore()
        const selectedMolecules = computed(() => filterStore.selectedDrugs)
        const searchText = ref('')

        watch(() => pharmacyListStore.moleculeList, newList => {
            moleculeListData.value = newList
        })
        const modifyArray = arr => {
            const newArray = arr.map(({ _id, ...rest }) => rest)
            const uniqueArray = [...new Set(newArray.map(JSON.stringify))].map(JSON.parse)

            return uniqueArray
        }

        const filteredMolecules = computed(() => {
            // Call modifyArray to remove _id property and remove duplicates from array
            const modifiedArray = modifyArray(moleculeListData.value)

            // Filter the modified array based on the search term
            return modifiedArray.filter(molecule => {
                const moleculeName = molecule.genericName.toLowerCase()
                const search = searchText.value.toLowerCase()

                return moleculeName.includes(search)
            })
        })

        return {
            filteredMolecules,
            filterStore,
            searchText,
            pharmacyListStore,
            selectedMolecules,
            moleculeListData,
            icons: {
                mdiAlertCircleOutline,
                mdiSelect,
                mdiSelectInverse
            }
        }
    },
    watch: {
        selectedMoleculesProp: {
            handler(val) {
                this.selectedMolecules = val
            },
            deep: true,
        },

    },
    created() {
    },
    async mounted() {
        this.moleculeListData = this.pharmacyListStore.moleculeList
    },
    methods: {
        updateSelectedDrugs(item) {
            // Update filterStore.selectedDrugs instead of local selectedMolecules
            const index = this.filterStore.selectedDrugs.findIndex(molecule => molecule.genericProductNumber === item.genericProductNumber)
            if (index === -1) {
                // Add the item to selectedDrugs if it doesn't already exist
                this.filterStore.selectedDrugs = [...this.filterStore.selectedDrugs, item]
            } else {
                // Remove the item from selectedDrugs if it already exists
                this.filterStore.selectedDrugs.splice(index, 1)
            }
        }

        // updateSelectedDrugs(item) {
        //     const index = this.selectedMolecules.findIndex(molecule => molecule.genericProductNumber === item.genericProductNumber)
        //     if (index === -1) {
        //         // Add the item to selectedMolecules if it doesn't already exist
        //         this.selectedMolecules = [...this.selectedMolecules, item]
        //     } else {
        //         // Remove the item from selectedMolecules if it already exists
        //         this.selectedMolecules.splice(index, 1)
        //     }
        //     this.filterStore.setSelectedDrugs(this.selectedMolecules)
        // },
    },
}
</script>
<style scoped>
.selected-pharmacy {
  font-weight: bold;
  font-size: 0.9em;
  color: #1e88e5; /* blue color, change as needed */
}
</style>
