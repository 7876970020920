<template>
  <div>
    <v-card flat>
      <v-card-title>
        <span class="selected-pharmacy">{{ (selectedPharmacy == null) ? 'Please select a pharmacy' : selectedPharmacy.pharmacyName }}</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="searchText"
          outlined
          dense
          label="Search pharmacies..."
        />
        <v-virtual-scroll
          :items="filteredPharmacies"
          :item-height="50"
          height="200"
        >
          <template #default="{ item }">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.pharmacyName }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  depressed
                  small
                  @click="selectPharmacy(item)"
                >
                  Select

                  <v-icon
                    v-if="item.Accreditation_Number !== filterStore.selectedPharmacy[0]?.Accreditation_Number"
                    color="orange darken-4"
                    right
                  >
                    {{ icons.mdiSelect }}
                  </v-icon>
                  <v-icon
                    v-if="item.Accreditation_Number === filterStore.selectedPharmacy[0]?.Accreditation_Number"
                    color="orange darken-4"
                    right
                  >
                    {{ icons.mdiSelectInverse }}
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { mdiAlertCircleOutline, mdiSelect, mdiSelectInverse } from '@mdi/js'
import { useFilterStore } from '@/stores/FilterStore'
import { useDataStore } from '@/stores/Data'
import { usePharmacyListStore } from '@/stores/PharmacyListStore'

export default {
    name: 'PharmacySelect',
    props: {
        selectedPharmacyProp: {
            type: Object,
            default: () => {},
        },
    },
    setup() {
        const pharmacyListStore = usePharmacyListStore()
        const selectedPharmacy = ref([])
        const pharmacyListData = ref([])
        const dataStore = useDataStore()

        const sortPharmaciesByName = pharmacyList => pharmacyList.sort((a, b) => {
            const pharmacyA = a.pharmacyName.toLowerCase()
            const pharmacyB = b.pharmacyName.toLowerCase()

            if (pharmacyA < pharmacyB) {
                return -1
            }
            if (pharmacyA > pharmacyB) {
                return 1
            }

            return 0
        })

        watch(() => pharmacyListStore.pharmacyList, newList => {
            pharmacyListData.value = sortPharmaciesByName(newList)
        })

        const filterStore = useFilterStore()
        const searchText = ref('')
        const pharmacyState = ref(true)
        const singleMode = computed(() => !filterStore.multiple)

        const filteredPharmacies = computed(() => pharmacyListData.value.filter(pharmacy => {
            const pharmacyName = pharmacy.pharmacyName.toLowerCase()
            const search = searchText.value.toLowerCase()

            return pharmacyName.includes(search)
        }))

        return {
            dataStore,
            searchText,
            filteredPharmacies,
            selectedPharmacy,
            pharmacyListData,
            filterStore,
            sortPharmaciesByName,
            pharmacyListStore,
            pharmacyState,
            singleMode,
            icons: {
                mdiAlertCircleOutline,
                mdiSelect,
                mdiSelectInverse
            }
        }
    },
    computed: {

    },
    watch: {

        selectedPharmacyProp: {
            handler(val) {
                this.selectedPharmacy = val
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        this.fetchPharmacyList()
    },

    methods: {
        updateSelectedPharmacy() {
            this.filterStore.setSelectedPharmacy([this.selectedPharmacy])
        },
        async selectPharmacy(pharmacy) {
            this.selectedPharmacy = pharmacy
            this.filterStore.selectedPharmacy = [pharmacy]
            await this.dataStore.fetchPharmacyMoleculeList()
        },
        async fetchPharmacyList() {
            this.pharmacyListData = this.sortPharmaciesByName(this.pharmacyListStore.pharmacyList)
        },
        resetPharmacy() {
            this.$emit('resetPharmacy')
            this.selectedPharmacy = []
            this.filterStore.setSelectedPharmacy([])
        },

    },
}
</script>

<style scoped>
.selected-pharmacy {
  font-weight: bold;
  font-size: 1.2em;
  color: #1e88e5;
}
</style>
