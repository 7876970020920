<template>
  <div>
    <v-row>
      <v-col>
        <!-- <app-bar-user-menu></app-bar-user-menu> -->

        <v-btn
          color="primary"
          text
          @click="toggleShowCard"
        >
          <v-icon
            dark
          >
            {{ icons.mdiFilterOutline }}
          </v-icon>
          <!-- <span
            v-if="$vuetify.breakpoint.smAndUp"
            class="tw-text-lg"
          >Filters</span> -->
        </v-btn>
        <!--
        <v-chip
          v-if="selectedStatus && selectedStatus.name && $vuetify.breakpoint.smAndUp"
          close
          @click:close="clearFilter"
        >
          {{ selectedStatus.name }}
        </v-chip> -->
        <!-- <v-chip
          v-if="filterStore.viewFilter && filterStore.viewFilter.name"
          color="success"
        >
          <v-icon left>
            {{ icons.mdiStarCheck }}
          </v-icon>
          {{ filterStore.viewFilter.name }}
        </v-chip> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiStarCheck, mdiFilterOutline, mdiMagnify } from '@mdi/js'
import { useFilterStore } from '@/stores/FilterStore'

export default {
    name: 'FilterCard',
    components: {

    },
    props: {

    },
    setup() {
        const filterStore = useFilterStore()
        const toggleShowCard = () => {
            filterStore.setShowCard(!filterStore.showCard)
        }

        return {
            filterStore,
            toggleShowCard
        }
    },
    data() {
        return {
            icons: {
                mdiStarCheck,
                mdiFilterOutline,
                mdiMagnify
            }
        }
    },

    methods: {
        clearFilter() {
            this.$emit('clearFilter')
        }
    }
}
</script>
