<template>
  <div>
    <v-expand-transition>
      <v-card
        v-show="showCard"
        class="tw-mx-3 border-secondary"
        outlined
        flat
        color="transparent"
      >
        <v-row
          class=" tw-py-3 pa-3"
        >
          <v-col
            cols="12"
            md="3"
          >
            <active-filter-card />

            <FilterOptionStatus
              v-if="currentRouteConfig.FilterOptionStatus"
              :filter-data.sync="filterData"
              :filtered-status="filteredStatus"
              @filterByStatus="filterByStatus"
            />
            <FilterOptionView
              v-if="currentRouteConfig.FilterOptionView"
            />
          </v-col>

          <v-col
            v-if="currentRouteConfig.FilterOptionThreshold"
            cols="12"
            md="3"
          >
            <v-card flat>
              <v-card-text>
                <FilterOptionThreshold />
                <FilterOptionMarketShare
                  :company-sales-name="companySalesName"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            v-if="currentRouteConfig.QuickDateSelect"
            cols="12"
            md="3"
          >
            <quick-select @refreshAllData="refreshAllData"></quick-select>
          </v-col>

          <!-- FIX THE PROPS -->
          <v-col
            v-if="currentRouteConfig.MoleculeSelect"
            cols="12"
            md="3"
          >
            <molecule-select></molecule-select>
          </v-col>
          <v-col
            v-if="currentRouteConfig.PharmacySelect"
            cols="12"
            md="3"
          >
            <pharmacy-select></pharmacy-select>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                block
                color="primary"
                @click="refreshAllData()"
              >
                <span>Apply</span>
                <v-icon
                  right
                  class="me-2"
                >
                  {{ icons.mdiPlaylistCheck }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                color="warning"
                @click="resetFilter()"
              >
                <span>Reset</span>
                <v-icon
                  right
                  class="me-2"
                >
                  {{ icons.mdiPlaylistCheck }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import {
    mdiRadioboxBlank,
    mdiRadioboxMarked,
    mdiMagnify,
    mdiCloseCircleOutline,
    mdiPlaylistCheck,
} from '@mdi/js'
import { useFilterStore } from '@/stores/FilterStore'
import { getCompanySalesName } from '@/util/functions'
import { status } from '@/components/filters/components'
import FilterOptionMarketShare from '@/components/filters/components/FilterOptionMarketShare.vue'
import FilterOptionThreshold from '@/components/filters/components/FilterOptionThreshold.vue'
import FilterOptionStatus from '@/components/filters/components/FilterOptionStatus.vue'
import FilterOptionView from '@/components/filters/components/FilterOptionView.vue'
import { useRouter } from '@core/utils'
import routeConfig from '@/assets/configs/routeConfig'
import pharmacySelect from '@/components/filters/pharmacySelect.vue'
import moleculeSelect from '@/components/filters/moleculeSelect.vue'
import activeFilterCard from '@/components/tables/activeFilterCard.vue'
import { checkAndExecuteDataStoreCalls } from '@/stores/checkAndExecuteDataStoreCalls'
import { usePharmacyListStore } from '@/stores/PharmacyListStore'
import { useDataStore } from '@/stores/Data'
import quickSelect from '@/components/datePickers/quickSelect.vue'

export default {
    name: 'DataFilter',
    components: {
        quickSelect,
        FilterOptionMarketShare,
        FilterOptionThreshold,
        FilterOptionStatus,
        FilterOptionView,
        moleculeSelect,
        pharmacySelect,
        activeFilterCard,
    },
    props: {
    },
    setup() {
        const { route } = useRouter()
        const currentRouteConfig = computed(() => routeConfig[route.value.path] || {}) // use routeConfig instead of filterConfig
        const filterStore = useFilterStore()
        const showCard = ref(filterStore.showCard)
        const filterData = ref({})
        const selectedStatus = ref({})
        const companySalesName = getCompanySalesName()
        const pharmacyListStore = usePharmacyListStore()
        const dataStore = useDataStore()
        watch(() => filterStore.showCard, newVal => {
            showCard.value = newVal
        })
        const showStatusFilter = computed(() => route.value.path !== '/home')
        const currentRoute = route.value.path

        return {
            currentRoute,
            checkAndExecuteDataStoreCalls,
            showStatusFilter,
            currentRouteConfig,
            route,
            selectedStatus,
            companySalesName,
            filterStore,
            pharmacyListStore,
            dataStore,
            filterData,
            showCard,
            status,
            icons: {
                mdiRadioboxBlank,
                mdiRadioboxMarked,
                mdiMagnify,
                mdiCloseCircleOutline,
                mdiPlaylistCheck,
            },
        }
    },

    computed: {
        filteredStatus() {
            return this.status
        },
    },
    watch: {
        filterData: {
            handler(val) {
                this.selectedStatus = this.filteredStatus[val]
            },
            immediate: true,
            deep: true,
        },

    },
    methods: {
        async refreshAllData() {
            this.checkAndExecuteDataStoreCalls(this.currentRoute, this.dataStore, this.filterStore, this.pharmacyListStore, true)
        },
        resetFilter() {
            this.filterStore.resetFilter()
            this.$router.push({ name: 'home' }).catch(() => {
                this.refreshAllData()
            })
        },
        filterByTarget(item) {
            this.$emit('filterByTarget', item)
        },
        clearFilter() {
            this.selectedStatus = {}
            this.filterData = {}
            this.$emit('filterData', this.selectedStatus)
        },
        activate() {
            this.$emit('updateShowCardProp', !this.showCard)
        },
        filterByStatus(statusPayload) {
        },
    },
}
</script>

<style lang="scss" scoped>
.my-list {
  padding: 0 !important;
}
.border-secondary {
  border: 1px solid var(--v-secondary-base) !important;
}
.placeholder-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  color: var(--v-secondary-base);
}
</style>
