/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia'
import { watch, watchEffect } from 'vue'
import { apiService } from '@/api'
import { useFilterStore } from '@/stores/FilterStore'
import { roundInt, updateSeriesWithColors } from '@/util/functions'
import { mergeData, sumOfSeries, totalSum } from '@/stores/storeHelprs'

export const useDataStore = defineStore('DataStore', {
    state: () => ({
        pharmacyMoleculeList: [],
        moleculePerMonthTableData: [],
        moleculeSnapshot: {
            totalSalesNum: 0,
            totalCompanySales: 0,
        },
        chartData: [],
        pharmacyRatesData: [],
        groupRebateValues: {},
        dataObject: {},
    }),

    getters: {
        stats: state => {
            const filterStore = useFilterStore()

            if (state.pharmacyMoleculeList.length > 0) {
                if (filterStore.viewFilter.value === 'targetView') {
                    // filter out all objects that contain target === false and return the new array
                    const filteredArray = state.pharmacyMoleculeList.filter(
                        item => item.target === true
              && item.salesSum > filterStore.threshold
              && item.marketShare < filterStore.marketShare,
                    )

                    const totalSalesNum = totalSum(filteredArray)

                    return {
                        totalSalesNum,
                    }
                }
                const totalSalesNum = totalSum(state.pharmacyMoleculeList)

                return {
                    totalSalesNum,
                }
            }
        },

        getDrugRank: state => {
            const FilterStore = useFilterStore()
            if (FilterStore.selectedDrugs === 0) {
                return 0
            }
            if (FilterStore.selectedDrugs.length > 0 && FilterStore.selectedDrugs[0].genericProductNumber) {
                if (state.pharmacyMoleculeList.length > 0) {
                    const index = state.pharmacyMoleculeList.findIndex(
                        item => item.genericProductNumber === FilterStore.selectedDrugs[0].genericProductNumber,
                    )

                    // return the rank of the selected drug
                    return index + 1
                }
            }
        },
        getTaroShare: state => {
            const FilterStore = useFilterStore()
            if (FilterStore.selectedDrugs === 0) {
                return 0
            }
            if (FilterStore.selectedDrugs.length > 0 && FilterStore.selectedDrugs[0].genericProductNumber) {
                const index = state.pharmacyMoleculeList.findIndex(
                    item => item.genericProductNumber === FilterStore.selectedDrugs[0].genericProductNumber,
                )

                // Check if index is valid and the object exists in the array
                if (index !== -1 && state.pharmacyMoleculeList[index]) {
                    const molecule = state.pharmacyMoleculeList[index]

                    // Check if taroSalesSum exists on the object
                    if (molecule.taroSalesSum !== undefined && molecule.taroSalesSum > 0 && molecule.salesSum > 0) {
                        return ((molecule.taroSalesSum / molecule.salesSum) * 100).toFixed(2)
                    }
                }

                return 0
            }

            // Uncomment if needed
            // return 0;
        },

        tableData: state => {
            if (state.pharmacyRatesData.length > 0 && state.groupRebateValues && state.pharmacyMoleculeList.length > 0) {
                return mergeData(state.pharmacyRatesData, state.groupRebateValues, state.pharmacyMoleculeList)
            }

            return []
        },
        quickStats: state => {
            const totalGenericPotential = sumOfSeries(state.chartData)

            return { totalGenericPotential }
        },
    },

    actions: {
        async setMoleculeSnapshot() {
            const fetchData = async key => {
                try {
                    const data = await apiService.getData(key, this.getFilterQuery)

                    // Check for empty array and return default value if so
                    if (Array.isArray(data) && data.length === 0) {
                        if (key === 'totalCompanySales') {
                            return [{ totalCompanySales: 0 }]
                        }
                    }

                    return data
                } catch (error) {
                    console.error(`Error fetching ${key}: `, error)

                    return null
                }
            }

            const [totalSalesNum, totalCompanySales] = await Promise.allSettled([
                fetchData('totalSalesNum'),
                fetchData('totalCompanySales'),
            ])

            // Extract values if the returned data are arrays with elements
            const totalSalesNumValue = totalSalesNum.status === 'fulfilled' && Array.isArray(totalSalesNum.value) && totalSalesNum.value.length > 0
                ? totalSalesNum.value[0].totalSalesNum
                : null

            const totalCompanySalesValue = totalCompanySales.status === 'fulfilled'
        && Array.isArray(totalCompanySales.value)
        && totalCompanySales.value.length > 0
                ? totalCompanySales.value[0].totalCompanySales
                : null

            this.moleculeSnapshot = {
                totalSalesNum: totalSalesNumValue,
                totalCompanySales: totalCompanySalesValue,
            }

            return this.moleculeSnapshot
        },

        /*
        setDataObject(key: string | string[], body = {}) => Promise<{ [key: string]: any }>
        This function sets the chartData object with data fetched from the API using the apiService.getData method.

        Parameters:
        key: a string or an array of strings representing the keys to be used as the property names for the chart data objects.
        body: an optional object that can be used to send additional data with the API request.
        Returns:
        A Promise that resolves to an object with the chart data organized by the provided keys. The property names of the object are the values of key and the property values are the resolved promises from the API requests.
        */
        async setDataObject(key, body = {}) {
            try {
                if (typeof key === 'string') {
                    key = [key]
                }

                const promises = key.map(async item => apiService.getData(item, body))
                const results = await Promise.allSettled(promises)

                results.forEach(({ status, value }, index) => {
                    if (this.chartData.hasOwnProperty(key[index])) {
                        // If key already exists, update the value
                        this.chartData[key[index]] = { status, value }
                    } else {
                        // If key doesn't exist, add new key-value pair
                        this.chartData = { ...this.chartData, [key[index]]: { status, value } }
                    }
                })
            } catch (error) {
                console.error('Error setting data object: ', error)
            }

            return this.chartData
        },

        async setPharmacyMoleculeList() {
            try {
                const [moleculeData, taroData] = await Promise.all([
                    apiService.getData('PharmacyMoleculeListTable', {}),
                    apiService.getData('PharmacyMoleculeListTable', { DIN: true }),
                ])

                this.pharmacyMoleculeList = moleculeData.map(molecule => {
                    const taroSale = taroData.find(taro => taro.genericProductNumber === molecule.genericProductNumber)

                    const taroSalesSum = taroSale ? taroSale.salesSum : 0
                    const marketShare = taroSale ? Number(roundInt((taroSale.salesSum / molecule.salesSum) * 100, 2)) : 0

                    return {
                        ...molecule,
                        taroSalesSum,
                        marketShare,
                    }
                })
            } catch (error) {
                console.error('Error setting pharmacy molecule list: ', error)
            }
        },

        async setRatesDrugList() {
            const [ratesDrugList] = await Promise.allSettled([apiService.getData('taroMoleculeListAgg', {})])
            this.pharmacyRatesData = ratesDrugList.value

            return this.pharmacyRatesData
        },
        async setPharmacyRatesData(body) {
            const filterStore = useFilterStore()
            const ratesSavedData = await apiService.setPharmacyRatesData({
                ...body,
                _id: filterStore.selectedPharmacy[0].Accreditation_Number,
            })

            return ratesSavedData
        },
        async getPharmacyRatesData() {
            const filterStore = useFilterStore()
            const groupRebateValues = await apiService.getPharmacyRatesData({
                _id: filterStore.selectedPharmacy[0].Accreditation_Number,
            })
            if (!Object.keys(groupRebateValues).length) {
                this.groupRebateValues = this.pharmacyRatesData.reduce((acc, item) => {
                    const { genericProductNumber, rebate } = item
                    acc[genericProductNumber] = rebate || 0

                    return acc
                }, {})

                return this.groupRebateValues
            }

            if (groupRebateValues.length === 0) {
                this.groupRebateValues = this.pharmacyRatesData.reduce((acc, item) => {
                    const { genericProductNumber, rebate } = item
                    acc[genericProductNumber] = rebate || 0

                    return acc
                }, {})
            } else {
                this.groupRebateValues = groupRebateValues[0]
            }

            return this.groupRebateValues
        },

        // Other actions
        resetState() {
            this.pharmacyMoleculeList = []
            this.moleculeSnapshot = {
                totalSalesNum: 0,
                totalCompanySales: 0,
            }
            this.chartData = []
            this.pharmacyRatesData = []
            this.groupRebateValues = {}
        },
        resetMoleculeSnapshot() {
            this.moleculeSnapshot = {
                totalSalesNum: 0,
                totalCompanySales: 0,
            }

            return this.moleculeSnapshot
        },

        // Filter related actions

        watchViewFilter() {
            const filterStore = useFilterStore()

            // watchEffect automatically tracks all reactive dependencies that are accessed during its execution. So, in your case, it will rerun this.filterByTarget() every time any reactive property accessed inside filterByTarget changes.

            watchEffect(() => {
                const { viewFilter, threshold, marketShare } = filterStore

                // Access the properties to make sure they're tracked as dependencies
                this.filterByTarget()
            })
        },
        filterByTarget() {
            const filterStore = useFilterStore()

            // Use a local variable to perform calculations
            let updatedData = this.pharmacyMoleculeList.map(item => {
                const newItem = { ...item }
                newItem.target = newItem.salesSum > filterStore.threshold && newItem.marketShare < filterStore.marketShare

                return newItem
            })

            if (filterStore.viewFilter.value === 'targetView') {
                // Filter the local variable, not the state directly
                updatedData = updatedData.filter(
                    item => item.salesSum > filterStore.threshold && item.marketShare < filterStore.marketShare,
                )
            }

            // Assign the result to the state
            this.moleculePerMonthTableData = updatedData
        },
        async refreshData({ seriesName, body }) {
            await this.setDataObject(seriesName, body)
        },

        async fetchPharmacyMoleculeList() {
            await this.setPharmacyMoleculeList()
        },

        async filterPharmacyData() {
            await this.filterByTarget()
        },

        async fetchSeriesData(seriesName) {
            const filterStore = useFilterStore()
            const response = await this.setDataObject(seriesName, filterStore.getFilterQuery)
            const data = response[seriesName]?.value?.data || []
            const seriesData = Array.isArray(data) ? updateSeriesWithColors(data) : []
            const xaxisCategories = response[seriesName]?.value?.xValues

            return { seriesData, xaxisCategories }
        },

        async fetchStackedSeriesData(seriesName) {
            const filterStore = useFilterStore()
            const response = await apiService.getData(seriesName, filterStore.getFilterQuery)
            const data = response.value?.data || []
            const seriesData = Array.isArray(data) ? updateSeriesWithColors(data) : []
            const xaxisCategories = response.value?.xValues

            return { seriesData, xaxisCategories }
        },

        async fetchChartData(seriesName) {
            this.data = await apiService.getData(seriesName, {})
            const colors = await this.getMfrColors(this.data.labels)
            this.defaultOptions = {
                ...this.defaultOptions,
                labels: this.data.labels,
                colors,
            }
            this.chartId += 1
        },

        async fetchMoleculeSnapshot() {
            await this.setMoleculeSnapshot()
        },
    },
})
