const dataStoreBodyRules = [
    {
        key: 'monthlyStackedSalesByMfrTar',
        bodyKeysToRemove: ['selectedDrugs'],
    },

    // Add more rules here as needed...
]

export default dataStoreBodyRules
