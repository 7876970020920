/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia'

export const useBreadcrumbs = defineStore('Breadcrumbs', {
    state: () => ({
        breadcrumbs: [], // Start with an empty array
    }),

    getters: {},

    actions: {
        addBreadcrumb(breadcrumb) {
            console.log('🚀 ~ file: Breadcrumbs.js:13 ~ addBreadcrumb ~ breadcrumb:', breadcrumb)

            // Don't add a breadcrumb for 'Home'
            if (breadcrumb.text === 'Home') return

            // Check if the breadcrumb already exists in the state
            const breadcrumbExists = this.breadcrumbs.some(b => b.text === breadcrumb.text && b.to === breadcrumb.to)

            // Add breadcrumb only if it doesn't already exist
            if (!breadcrumbExists) {
                this.breadcrumbs.push(breadcrumb)
            }
        },
        removeBreadcrumb(text) {
            const index = this.breadcrumbs.findIndex(b => b.text === text)
            if (index >= 0) {
                this.breadcrumbs.splice(index + 1)
            }
        },
        clearBreadcrumbs() {
            this.breadcrumbs = []
        },
    },
})
