<!-- FilterOptionStatus.vue -->
<template>
  <div>
    <span class="tw-pl-3 tw-text-base tw-font-black">Status (WIP)</span>
    <v-divider class="tw-mt-3"></v-divider>
    <v-list
      dense
      outlined
      class="my-list tw-mt-6"
    >
      <v-list-item-group
        :value="localFilterData"
        color="warning"
        @change="updateFilterData"
      >
        <template
          v-for="item in filteredStatus"
        >
          <v-list-item
            :key="item.value"
            @click="filterByStatus(item)"
          >
            <template #default="{ active }">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                  <span v-if="item.value === 'up' || item.value === 'down'">
                    {{ item.value === 'up' ? '📈' : '📉' }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>{{ item.action }}</v-list-item-action-text>
                <v-icon v-if="!active">
                  {{ icons.mdiRadioboxBlank }}
                </v-icon>
                <v-icon
                  v-else
                  color="warning"
                >
                  {{ icons.mdiCloseCircleOutline }}
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import {
    mdiRadioboxBlank,

    mdiCloseCircleOutline,
} from '@mdi/js'

export default {
    name: 'FilterOptionStatus',
    props: {
        filterData: {
            type: [Object, Number],
            required: false,
        },
        filteredStatus: {
            type: Array,
            required: true,
        },
    },
    setup() {
        return {
            icons: {
                mdiRadioboxBlank,

                mdiCloseCircleOutline,
            },
        }
    },
    data() {
        return {
            localFilterData: this.filterData,
        }
    },
    watch: {
        filterData: {
            handler(newValue) {
                this.localFilterData = newValue
            },
            immediate: true,
        },
    },
    methods: {
        updateFilterData(newFilterData) {
            this.localFilterData = newFilterData
            this.$emit('update:filterData', this.localFilterData)
        },
        filterByStatus(item) {
            this.$emit('filterByStatus', item)
        },
    },
}
</script>
