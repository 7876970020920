import { mdiTrendingUp, mdiHomeOutline } from '@mdi/js'

export default [
    {
        title: 'Reports',
        icon: mdiHomeOutline,
        children: [
            {
                title: 'Sales & Market Share Analysis',
                icon: mdiTrendingUp,
                to: 'sales-market-share-analysis',
            },
        ],
    },
]
