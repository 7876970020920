<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <data-filter
      id="data-filter"
    ></data-filter>
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view>
      </router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref } from 'vue'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'
import DataFilter from '@/components/filters/DataFilter.vue'

// Layouts
import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

export default {
    name: 'PhoxIQApp',
    components: {
        LayoutContentVerticalNav,
        LayoutContentHorizontalNav,
        LayoutBlank,
        DataFilter
    },
    setup() {
        const showCard = ref(false)
        const { route } = useRouter()
        const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()
        const { handleBreakpointLayoutSwitch } = useLayout()
        handleBreakpointLayoutSwitch()
        const resolveLayoutVariant = computed(() => {
            if (route.value.meta.layout === 'blank') return 'layout-blank'
            if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

            return null
        })

        useDynamicVh()

        return {
            showCard,
            resolveLayoutVariant,
            appSkinVariant,
            appRouteTransition,
        }
    },
    created() {

    },
    methods: { },
}
</script>
