import { getInstance } from '@/auth/authWrapper'

export function mergeData(pharmacyRatesData, groupRebateValues, pharmacyMoleculeList) {
    if (!Object.keys(groupRebateValues).length) {
        console.error('groupRebateValues is an empty object')

        return []
    }

    const tableData = pharmacyRatesData.map(rate => {
        const foundMolecule = pharmacyMoleculeList.find(
            molecule => molecule.genericProductNumber === rate.genericProductNumber,
        )

        return {
            ...rate,
            ...foundMolecule,
            rebate: groupRebateValues[rate.genericProductNumber].toString(),
        }
    })

    return tableData
}

export function sumOfSeries(chartData) {
    // Get the mfrArray from the instance's user object
    const mfrArray = getInstance().user['https://api.pharmaguide.ca/claims/app_metadata'].mfr

    try {
        const { labels, series } = chartData.MfrMarketSharePieChartWatchList.value

        let selectedMfrSales = 0

        // Loop through mfrArray to find and add corresponding sales
        // eslint-disable-next-line no-restricted-syntax
        for (const mfr of mfrArray) {
            const mfrIndex = labels.indexOf(mfr)
            if (mfrIndex !== -1) {
                selectedMfrSales += series[mfrIndex]
            }
        }

        // Calculate total and market share
        const total = series.reduce((sum, value) => sum + value, 0)
        const selectedMfrMarketShare = (selectedMfrSales / total) * 100

        return {
            total,
            selectedMfrSales,
            selectedMfrMarketShare: `${selectedMfrMarketShare.toFixed(2)}%`,
        }
    } catch (error) {
        return 'Error: Required data not found'
    }
}

export function totalSum(arr) {
    const result = arr.reduce(
        (acc, curr) => {
            acc.sumOfSales += curr.salesSum || 0
            acc.sumOfTaroSales += curr.taroSalesSum || 0

            return acc
        },
        { sumOfSales: 0, sumOfTaroSales: 0 },
    )

    result.sumOfSales = Number(result.sumOfSales.toFixed(2))
    result.sumOfTaroSales = Number(result.sumOfTaroSales.toFixed(2))

    result.marketShare = (result.sumOfTaroSales / result.sumOfSales) * 100
    result.marketShare = `${Number(result.marketShare.toFixed(2))}%`

    return result
}
