/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia'
import { getDateRange } from '@/util/functions'

export const useFilterStore = defineStore('FilterStore', {
    state: () => ({
        showCard: false,
        dateRange: getDateRange('Last 180 Days'),
        groupCode: [],
        selectedDrugs: [],
        mfr: [],
        threshold: 1000,
        marketShare: 20,
        viewFilter: {},
        selectedPharmacy: [],
        singleMode: true,
    }),

    getters: {
        getFilterQuery: state => {
            let query = {}
            if (state.selectedPharmacy.length > 0) {
                query = { ...query, Accreditation_Number: state.selectedPharmacy.map(item => item.Accreditation_Number) }
            }
            if (state.dateRange.length > 0) {
                query = { ...query, RxDate: [`${state.dateRange[0]}`, `${state.dateRange[1]}T23:59:59Z`] }
            }
            if (state.selectedDrugs.length > 0) {
                query = {
                    ...query,
                    selectedDrugs: state.selectedDrugs,
                    genericProductNumber: state.selectedDrugs.map(item => item.genericProductNumber),
                }
            } else {
                query = { ...query, selectedDrugs: [], genericProductNumber: [] }
            }

            return query
        },
    },

    actions: {
        setDateRange(date) {
            this.dateRange = date
        },
        setShowCard(showCard) {
            this.showCard = showCard
        },
        setGroupCode(groupCode) {
            this.groupCode = groupCode
        },
        setMfr(mfr) {
            this.mfr = mfr
        },
        setThreshold(threshold) {
            this.threshold = threshold
        },
        setMarketShare(marketShare) {
            this.marketShare = marketShare
        },
        setViewFilter(data) {
            this.viewFilter = data
        },
        setSelectedDrugs(selectedDrugs) {
            this.selectedDrugs = selectedDrugs
        },
        setSelectedPharmacy(selectedPharmacy) {
            this.selectedPharmacy = selectedPharmacy
        },
        setSingleMode(singleMode) {
            this.singleMode = singleMode
        },
        resetFilter() {
            this.dateRange = getDateRange('Last 180 Days')
            this.groupCode = []
            this.selectedDrugs = []
            this.mfr = []
            this.threshold = 1000
            this.marketShare = 20
            this.viewFilter = {}
            this.selectedPharmacy = []
            this.singleMode = true
        },
    },
})
