const status = [
    {
        name: 'Trending Up',
        value: 'up',
        color: 'success',
    },
    {
        name: 'Trending Down',
        value: 'down',
        color: 'error',
    },
    {
        name: 'Single Source',
        value: 'SS',
    },
    {
        name: 'Frequent Backorder',
        value: 'BO',
    },
]

export default status
