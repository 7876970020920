const viewsList = [
    {
        name: 'Targets Only',
        value: 'targetView',
    },
    {
        name: 'Ordering Intervals',
        value: 'orderingIntervals',
    },
]

export default viewsList
