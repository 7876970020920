import { mdiTableColumnWidth, mdiHospitalBuilding, mdiTableKey } from '@mdi/js'

export default [
    {
        title: 'Analytics', // or any title you prefer
        icon: mdiHospitalBuilding, // replace with an icon that represents 'Analytics'
        children: [
            {
                title: 'Store List',
                icon: mdiHospitalBuilding,
                to: 'home',
            },
            {
                title: 'Molecule List',
                icon: mdiTableColumnWidth,
                to: 'pharmacy-molecule-list',
            },
            {
                title: 'Drug Details',
                icon: mdiTableKey,
                to: 'molecule-dashboard',
            },
        ],
    },
]
