/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { getInstance } from '@/auth/authWrapper'

export const authGuard = (to, from, next) => {
    const authService = getInstance()

    const fn = () => {
        try {
            if (authService.isAuthenticated) {
                return next()
            }

            return authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
        } catch (error) {
            console.error('Error during authentication:', error)
            next('/error-404') // or wherever you want to redirect in case of an error
        }
    }

    if (!authService.loading) {
        return fn()
    }

    authService.$watch('loading', loading => {
        if (loading === false) {
            return fn()
        }
    })
}
