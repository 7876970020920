<template>
  <div>
    <span class="tw-pl-3 tw-text-base tw-font-black">Minimum Target Value ($)</span>
    <v-divider class="tw-mt-3"></v-divider>
    <v-slider
      :value="localThreshold"
      class="tw-mt-12"
      hint="Adjust Threshold"
      max="15000"
      min="0"
      thumb-label="always"
      step="1000"
      ticks="always"
      tick-size="2"
      @input="updateThreshold"
    ></v-slider>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useFilterStore } from '@/stores/FilterStore'

export default {
    name: 'FilterOptionThreshold',
    setup() {
        const filterStore = useFilterStore()

        const localThreshold = computed({
            get: () => filterStore.threshold,
            set: value => filterStore.setThreshold(value),
        })

        return {
            localThreshold,
            updateThreshold: value => {
                filterStore.setThreshold(value)
            },
        }
    },
}
</script>
