// mfrUtil.js
import { getInstance } from '@/auth/authWrapper'

function getCompanySalesName() {
    const mfrArray = getInstance().user['https://api.pharmaguide.ca/claims/app_metadata'].mfr
    let companySalesName = '' // Default value

    if (Array.isArray(mfrArray)) {
        if (mfrArray.includes('TAR') && mfrArray.includes('RAN')) {
            companySalesName = 'Taro'
        } else if (mfrArray.includes('SDZ')) {
            companySalesName = 'Sandoz'
        }
    }

    return companySalesName
}

export default getCompanySalesName
