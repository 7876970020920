<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>
    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <v-app-bar
        color="transparent"
        flat
      >
        <div class="d-flex align-center">
          <v-icon
            v-if="!isVerticalNavMenuActive"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <v-breadcrumbs
            v-if="$vuetify.breakpoint.mdAndUp"

            :items="breadcrumbs.breadcrumbs"
          ></v-breadcrumbs>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <FilterCard />
          <active-filter v-if="$vuetify.breakpoint.mdAndUp"></active-filter>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-right">
        </div>
      </v-app-bar>
    </template>
    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
          href="https://pharmaguide.ca"
          class="text-decoration-none"
        >PharmaGuide Inc.</a><span class="d-none d-md-inline">, All rights Reserved</span></span>
        <div class="align-center d-none d-md-flex">
          <span>Hand-crafted &amp; Made with</span>
          <v-icon
            color="error"
            class="ms-2"
          >
            {{ icons.mdiHeartOutline }}
          </v-icon>
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import { mdiMenu, mdiHeartOutline, mdiHomeCircle } from '@mdi/js'
import { useFilterStore } from '@/stores/FilterStore'
import FilterCard from '@/components/filters/components/FilterCard.vue'
import activeFilter from '@/components/tables/activeFilter.vue'

import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/vertical'
import { useUserStore } from '@/stores/User'
import { useBreadcrumbs } from '@/stores/Breadcrumbs'

// App Bar Components
import { sentryUser } from '@/api/sentry/sentry'
import { useRouter, useRoute } from 'vue-router/composables'
import { getVuetify } from '@core/utils'

export default {
    components: {
        LayoutContentVerticalNav,
        activeFilter,

        FilterCard
    },
    setup() {
        const filterStore = useFilterStore()

        const vuetify = getVuetify()
        const breadcrumbs = useBreadcrumbs()
        const router = useRouter()
        const route = useRoute()
        const userStore = useUserStore()
        const items = [
            {
                disabled: false,
                exact: true,
                text: 'Home',
                to: '/home'
            }
        ]
        const toggleShowCard = () => {
            filterStore.setShowCard(!filterStore.showCard)
        }
        const goHome = () => {
            if (route.name === 'home') return

            router.push({ name: 'home' })
        }

        return {
            toggleShowCard,
            filterStore,
            vuetify,
            breadcrumbs,
            items,
            route,
            router,
            goHome,
            sentryUser,
            userStore,
            navMenuItems,
            icons: {
                mdiMenu,
                mdiHeartOutline,
                mdiHomeCircle
            },
        }
    },
    mounted() {
        if (process.env.NODE_ENV !== 'development') {
            sentryUser()
        }
    },
}
</script>

<style lang="scss" scoped>
// .navbar-content-container {
//   height: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   flex-grow: 1;
//   position: relative;
// }
</style>
