import '@/styles/styles.scss'
import { createPinia, PiniaVuePlugin } from 'pinia'
import Vue from 'vue'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { Replay } from '@sentry/replay'
import VueApexCharts from 'vue-apexcharts'
import { audience, clientId, domain } from '../auth_config.json'
import App from './App.vue'
import './assets/tailwind.css'
import { Auth0Plugin, getInstance } from './auth'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import wb from './registerServiceWorker'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(VueApexCharts)

Vue.component('ApexChart', VueApexCharts)

Vue.prototype.$workbox = wb

const IS_PROD = process.env.NODE_ENV !== 'development'

const APP_VERSION = process.env.VUE_APP_VERSION

Vue.config.productionTip = false
Vue.component('VueDraggableResizable', VueDraggableResizable)
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    useRefreshTokens: true,
    cacheLocation: 'localstorage', // valid values are: 'memory' or 'localstorage'
    onRedirectCallback: appState => {
        router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
    },
})

let SENTRY_ENABLED = false
if (IS_PROD) {
    SENTRY_ENABLED = true
    Sentry.init({
        Vue,
        trackComponents: true,
        dsn: 'https://d0387e48d7764231b74add059e02088d@o422586.ingest.sentry.io/4504317691691008',
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new Replay(),
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['*.phox.app', 'localhost', '*.pharmaguide.ca', /^\//],
            }),
        ],
        tracesSampleRate: 0.3,
        release: APP_VERSION,
    })
} else {
    console.table({
        IS_PROD,
        PHOX_DOCS_VERSION: APP_VERSION,
        GEN_DASH_API: process.env.VUE_APP_CHART_API,
        NODE_ENV: process.env.NODE_ENV,
        SENTRY_ENABLED,
    })
}

// Get the Auth0 instance
const auth = getInstance()

// Wait for auth0 client to be ready
auth.$watch('loading', loading => {
    if (loading === false) {
        new Vue({
            router,
            store,
            vuetify,
            pinia,
            render: h => h(App),
        }).$mount('#app')
    }
})
