import { mdiCalendar, mdiFilterRemove } from '@mdi/js'

const filters = [
    {
        title: 'Date',
        icon: mdiCalendar,
        iconColor: 'primary',
        dialog: false,
        component: 'datePicker',
        route: null,
        maxWidth: '600px',
        needsPharmacy: false,
        fullscreen: false,
        tooltip: 'Date Range Filter',
    },

    // {
    //     title: 'Pharmacy',
    //     icon: mdiHospital,
    //     iconColor: 'primary',
    //     dialog: false,
    //     component: 'pharmacySelect',
    //     route: null,
    //     maxWidth: '600px',
    //     needsPharmacy: false,
    //     fullscreen: false,
    //     tooltip: 'Pharmacy Filter',
    // },
    // {
    //     title: 'Molecule',
    //     icon: mdiTestTube,
    //     iconColor: 'primary',
    //     dialog: false,
    //     component: 'moleculeSelect',
    //     route: null,
    //     maxWidth: '600px',
    //     needsPharmacy: false,
    //     fullscreen: false,
    //     tooltip: 'Molecule Filter',
    // },

    // {
    //     title: 'Rates',
    //     icon: mdiTag,
    //     iconColor: 'primary',
    //     dialog: false,
    //     component: null,
    //     route: 'sos-rates-table',
    //     maxWidth: '100%',
    //     needsPharmacy: true,
    //     fullscreen: true,
    //     tooltip: 'Click here to view and customize rates for the selected pharmacy',
    // },
    // {
    //     title: 'Reset',
    //     icon: mdiFilterRemove,
    //     txtBtn: 'Reset',
    //     iconColor: 'error',
    //     dialog: false,
    //     component: '',
    //     maxWidth: '100%',
    //     needsPharmacy: false,
    //     fullscreen: false,
    //     tooltip: 'Reset Filters',
    // },
]

export default filters
