/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia'
import { apiService, chartService } from '@/api'

// const demoModePharmacyNames = {
//     'Britannia Health Pharmacy': 'Demo Pharmacy 1',
//     'Bur Oak Discount Pharmacy': 'Demo Pharmacy 2',
//     'Castlemore Discount Pharmacy': 'Demo Pharmacy 3',
//     'Denison Discount Pharmacy': 'Demo Pharmacy 4',
//     'Finch Discount Pharmacy': 'Demo Pharmacy 5',
//     'King Medical Arts Pharmacy': 'Demo Pharmacy 6',
//     'Markham Discount Pharmacy': 'Demo Pharmacy 7',
//     'Midtown Medical Pharmacy': 'Demo Pharmacy 8',
//     'Origins Pharmacy & Compounding Lab': 'Demo Pharmacy 9',
//     'ORIGINS Pharmacy & Home Health Care Centre': 'Demo Pharmacy 10',
//     'Origins Pharmacy Milton': 'Demo Pharmacy 11',
//     'Pharma Viva Pharmacy': 'Demo Pharmacy 12',
//     'QueenLynch Pharmacy': 'Demo Pharmacy 13',
//     'St. George Pharmacy': 'Demo Pharmacy 14',
//     'Terry Fox Pharmacy': 'Demo Pharmacy 15',
//     'Total Health Pharmacy': 'Demo Pharmacy 16',
// }

const demoModePharmacyNames = {
    'Britannia Health Pharmacy': 'Sunrise HealthMart',
    'Bur Oak Discount Pharmacy': 'Moonbeam Drugstore',
    'Castlemore Discount Pharmacy': 'Evergreen Pharma',
    'Denison Discount Pharmacy': 'Pebble Creek Pharmacy',
    'Finch Discount Pharmacy': 'Lavender Meds',
    'King Medical Arts Pharmacy': 'Royal Health Emporium',
    'Markham Discount Pharmacy': 'Willow Tree Pharma',
    'Midtown Medical Pharmacy': 'Metro MedShop',
    'Origins Pharmacy & Compounding Lab': 'Naturesync Pharmacy',
    'ORIGINS Pharmacy & Home Health Care Centre': 'Earthtone HealthPoint',
    'Origins Pharmacy Milton': 'Mystic Herbals',
    'Pharma Viva Pharmacy': 'VividLife MedStore',
    'QueenLynch Pharmacy': 'Regal Wellness',
    'St. George Pharmacy': 'DragonHeart Pharmacy',
    'Terry Fox Pharmacy': 'Foxglove Health',
    'Total Health Pharmacy': 'Universe Health Oasis',
}

export const usePharmacyListStore = defineStore('PharmacyListStore', {
    state: () => ({
        pharmacyList: [],
        moleculeList: [],
        loading: false,
        isDemoMode: true, // Add a new state to handle demo mode
    }),

    getters: {
        alphabetizedPharmacyList() {
            return this.pharmacyList.length === 0 ? [] : this.pharmacyList
        },
        pharmacyListBySales() {
            return this.pharmacyList.length === 0 ? [] : [...this.pharmacyList].sort((a, b) => b.sales - a.sales)
        },
    },

    actions: {
        async setPharmacyList() {
            this.pharmacyList = await apiService.getData('pharmacyList', {})

            // Check for demo mode and replace names
            if (this.isDemoMode) {
                this.pharmacyList.forEach(pharmacy => {
                    pharmacy.pharmacyName = demoModePharmacyNames[pharmacy.pharmacyName] || pharmacy.pharmacyName
                })
            }

            return this.pharmacyList
        },
        async setMoleculeList() {
            this.moleculeList = await chartService.GenDashDrugs({})

            return this.moleculeList
        },
        resetState() {
            this.pharmacyList = []
            this.moleculeList = []
        },
        toggleDemoMode() {
            this.isDemoMode = !this.isDemoMode
        },
        async init() {
            await Promise.all([this.setPharmacyList(), this.setMoleculeList()])
        },
    },
})
