<template>
  <div>
    {{ summary }}
  </div>
</template>
<script>
import { ref, watch, computed } from 'vue'
import { useFilterStore } from '@/stores/FilterStore'
import { mdiPill, mdiDelete } from '@mdi/js'

export default {
    name: 'ActiveFilter',
    setup() {
        const filterStore = useFilterStore()
        const dateRange = ref(filterStore.dateRange)
        const selectedPharmacy = ref(filterStore.selectedPharmacy)
        const selectedDrugs = ref(filterStore.selectedDrugs)
        watch(
            () => filterStore.dateRange,
            newValue => {
                dateRange.value = newValue
            }
        )

        watch(
            () => filterStore.selectedPharmacy,
            newValue => {
                selectedPharmacy.value = newValue
            }
        )

        watch(
            () => filterStore.selectedDrugs,
            newValue => {
                selectedDrugs.value = newValue
            }
        )

        const summary = computed(() => {
            const summaryArray = []

            if (dateRange.value && dateRange.value.length === 2) {
                const start = new Date(dateRange.value[0])
                const end = new Date(dateRange.value[1])
                summaryArray.push(`${start.getMonth() + 1}/${start.getDate()} - ${end.getMonth() + 1}/${end.getDate()}`)
            }

            if (selectedPharmacy.value && selectedPharmacy.value.length > 0) {
                summaryArray.push(`${selectedPharmacy.value[0].pharmacyName}`)
            }

            if (selectedDrugs.value && selectedDrugs.value.length > 0) {
                const drugsNames = selectedDrugs.value.map(drug => drug.genericName).join(', ')
                summaryArray.push(drugsNames)
            }

            return summaryArray.join(' | ')
        })

        const clearSelectedPharmacy = () => {
            filterStore.setSelectedPharmacy([]) // Reset the specific filter
        }
        const clearSelectedDrug = drug => {
            const updatedSelectedDrugs = selectedDrugs.value.filter(d => d.id !== drug.id)
            filterStore.setSelectedDrugs(updatedSelectedDrugs) // Reset the specific filter
        }

        return {
            summary,
            dateRange,
            selectedPharmacy,
            selectedDrugs,
            clearSelectedPharmacy,
            clearSelectedDrug,
            icons: {
                mdiPill,
                mdiDelete
            },
        }
    },

// leaving this as an example, will be removed soon
    //  destroyed() {
    //     this.filterStoreUnsubscribe()
    // },
    // async mounted() {
    //     this.filterStoreUnsubscribe = this.filterStore.$onAction(({ name, after }) => {
    //         after(() => {
    //             if (name === 'refreshCharts') {
    //                 //   this.getMongoChartData()
    //             }
    //         })
    //     })
    // },
}
</script>

<style scoped>
.filter-button {
    min-width: 100px;
    /* max-width: 150px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

 .filter-summary-card {
    /* background-color: #F8F8F8; */
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 0px;
  }

  .filter-summary-title {
    font-size: 16px;
    font-weight: 600;
    /* color: #555555; */
  }

  .filter-summary-value {
    font-size: 14px;
    /* color: #333333; */
  }

  .selected-drugs {
    margin: 0;
    padding-left: 20px;
  }

  .selected-drugs li {
    font-size: 14px;
    /* color: #333333; */
    list-style-type: disc;
    margin-bottom: 5px;
  }
</style>
