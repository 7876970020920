<template>
  <v-card
    flat
    class="filter-summary-card"
  >
    <v-card-text>
      <div class="filter-summary-title">
        <div class="inline-elements">
          Date Range:
          <app-bar-filter></app-bar-filter>
        </div>
      </div>
      <div
        v-if="dateRange && dateRange.length === 2"
        class="filter-summary-value"
      >
        {{ dateRange[0] }} - {{ dateRange[1] }}
      </div>
      <div
        v-else
        class="filter-summary-value"
      >
      </div>
      <br>
      <div class="filter-summary-title">
        Pharmacy:
      </div>
      <div
        v-if="selectedPharmacy && selectedPharmacy.length > 0"
        class="filter-summary-value"
      >
        {{ selectedPharmacy[0].pharmacyName }}
        <v-icon
          color="error"
          small
          class="mx-2"
          @click="clearSelectedPharmacy"
        >
          {{ icons.mdiDelete }}
        </v-icon>
      </div>
      <div
        v-else
        class="filter-summary-value"
      >
      </div>
      <br>
      <div class="filter-summary-title">
        Drug:
      </div>
      <div
        v-if="selectedDrugs && selectedDrugs.length > 0"
        class="filter-summary-value"
      >
        <ul class="selected-drugs">
          <li
            v-for="(drug, index) in selectedDrugs"
            :key="drug.id"
          >
            {{ drug.genericName }}
            <v-icon
              color="error"
              small
              class="mx-2"
              @click="clearSelectedDrug(drug)"
            >
              {{ icons.mdiDelete }}
            </v-icon>
          </li>
        </ul>
      </div>
      <div
        v-else
        class="filter-summary-value"
      >
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { ref, watch, computed } from 'vue'
import { useFilterStore } from '@/stores/FilterStore'
import { mdiPill, mdiDelete } from '@mdi/js'
import AppBarFilter from '@/components/AppBar/AppBarFilter.vue'

export default {
    name: 'ActiveFilter',
    components: {
        AppBarFilter
    },
    setup() {
        const filterStore = useFilterStore()
        const dateRange = ref(filterStore.dateRange)
        const selectedPharmacy = ref(filterStore.selectedPharmacy)
        const selectedDrugs = ref(filterStore.selectedDrugs)
        watch(
            () => filterStore.dateRange,
            newValue => {
                dateRange.value = newValue
            }
        )

        watch(
            () => filterStore.selectedPharmacy,
            newValue => {
                selectedPharmacy.value = newValue
            }
        )

        watch(
            () => filterStore.selectedDrugs,
            newValue => {
                selectedDrugs.value = newValue
            }
        )

        // const summary = computed(() => {
        //     const summaryArray = []

        //     if (dateRange.value && dateRange.value.length === 2) {
        //         summaryArray.push(`${dateRange.value[0]} - ${dateRange.value[1]}`)
        //     }

        //     if (selectedPharmacy.value && selectedPharmacy.value.length > 0) {
        //         summaryArray.push(`${selectedPharmacy.value[0].pharmacyName}`)
        //     }

        //     if (selectedDrugs.value && selectedDrugs.value.length > 0) {
        //         const firstDrug = selectedDrugs.value[0]
        //         const otherDrugsCount = selectedDrugs.value.length - 1

        //         if (otherDrugsCount > 0) {
        //             const limitedGenericName = firstDrug.genericName.slice(0, 10)
        //             summaryArray.push(`${limitedGenericName} + ${otherDrugsCount} more`)
        //         } else {
        //             const limitedGenericName = firstDrug.genericName.slice(0, 10)
        //             summaryArray.push(limitedGenericName)
        //         }
        //     }

        //     return summaryArray.join(' | ')
        // })
        const summary = computed(() => {
            const summaryArray = []

            if (dateRange.value && dateRange.value.length === 2) {
                const start = new Date(dateRange.value[0])
                const end = new Date(dateRange.value[1])
                summaryArray.push(`${start.getMonth() + 1}/${start.getDate()} - ${end.getMonth() + 1}/${end.getDate()}`)
            }

            if (selectedPharmacy.value && selectedPharmacy.value.length > 0) {
                summaryArray.push(`${selectedPharmacy.value[0].pharmacyName.slice(0, 10)}...`)
            }

            // if (selectedDrugs.value && selectedDrugs.value.length > 0) {
            //     summaryArray.push(mdiPill) // You may need to adjust this based on how you display icons in your project.
            // }

            return summaryArray.join(' | ')
        })
        const clearSelectedPharmacy = () => {
            filterStore.setSelectedPharmacy([]) // Reset the specific filter
        }
        const clearSelectedDrug = drug => {
            const updatedSelectedDrugs = selectedDrugs.value.filter(d => d.id !== drug.id)
            filterStore.setSelectedDrugs(updatedSelectedDrugs) // Reset the specific filter
        }

        return {
            summary,
            dateRange,
            selectedPharmacy,
            selectedDrugs,
            clearSelectedPharmacy,
            clearSelectedDrug,
            icons: {
                mdiPill,
                mdiDelete
            },
        }
    },

// leaving this as an example, will be removed soon
    //  destroyed() {
    //     this.filterStoreUnsubscribe()
    // },
    // async mounted() {
    //     this.filterStoreUnsubscribe = this.filterStore.$onAction(({ name, after }) => {
    //         after(() => {
    //             if (name === 'refreshCharts') {
    //                 //   this.getMongoChartData()
    //             }
    //         })
    //     })
    // },
}
</script>

<style scoped>
.filter-button {
    min-width: 100px;
    /* max-width: 150px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

 .filter-summary-card {
    /* background-color: #F8F8F8; */
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 0px;
  }

  .filter-summary-title {
    font-size: 16px;
    font-weight: 600;
    /* color: #555555; */
  }

  .filter-summary-value {
    font-size: 14px;
    /* color: #333333; */
  }

  .selected-drugs {
    margin: 0;
    padding-left: 20px;
  }

  .selected-drugs li {
    font-size: 14px;
    /* color: #333333; */
    list-style-type: disc;
    margin-bottom: 5px;
  }
  .inline-elements {
  display: flex;
  align-items: center;
}
</style>
