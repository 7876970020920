<template>
  <div>
    <span class="tw-pl-3 tw-text-base tw-font-black">{{ companySalesName }} Market Share < {{ localMarketShare }}%</span>
    <v-divider class="tw-mt-3"></v-divider>
    <v-slider
      :value="localMarketShare"
      class="tw-mt-12"
      hint="Adjust Threshold"
      max="100"
      min="0"
      thumb-label="always"
      step="10"
      ticks="always"
      tick-size="2"
      @input="updateMarketShare"
    ></v-slider>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useFilterStore } from '@/stores/FilterStore'

export default {
    name: 'FilterOptionMarketShare',
    props: {
        companySalesName: {
            type: String,
            required: true
        }
    },
    setup() {
        const filterStore = useFilterStore()

        const localMarketShare = computed({
            get: () => filterStore.marketShare,
            set: value => filterStore.setMarketShare(value),
        })

        return {
            localMarketShare,
            updateMarketShare: value => {
                filterStore.setMarketShare(value)
            },
        }
    },
}
</script>
