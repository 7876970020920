import pharmacy from './pharmacy'
import iqReport from './iqReport'
import dashboard from './dashboard'

// import { mdiHomeOutline } from '@mdi/js'
// import sos from './sos'
// import tables from './tables'
// import market from './market'

export default [
    ...pharmacy,
    ...iqReport,

    // ...dashboard,

    // {
    //     title: 'Home',
    //     icon: mdiHomeOutline,
    //     to: 'home',
    // },
    // ...sos,
]
