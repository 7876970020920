function arrayToObject(data) {
    const result = {}
    data.forEach(d => {
        result[d.name] = d
        delete d.name
    })

    return result
}

export default arrayToObject
