import { mdiTableColumnWidth, mdiHospitalBuilding, mdiTableKey } from '@mdi/js'

export default [
    {
        subheader: 'Pharmacy',
    },
    {
        title: 'Store List',
        icon: mdiHospitalBuilding,
        to: 'home',
    },
    {
        title: 'Molecule List',
        icon: mdiTableColumnWidth,
        to: 'pharmacy-molecule-list',
    },
    {
        title: 'Drug Details',
        icon: mdiTableKey,
        to: 'molecule-dashboard',
    },
]
