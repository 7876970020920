<template>
  <div>
    <!-- <span class="tw-pl-3 tw-text-base tw-font-black">View</span> -->
    <v-divider class="tw-mt-3"></v-divider>
    <v-list
      dense
      outlined
      class="my-list tw-mt-6"
    >
      <v-list-item-group
        v-model="localActiveView"
        color="warning"
      >
        <template v-for="item in viewsList">
          <v-list-item :key="item.value">
            <template #default="{ active }">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="!active">
                  {{ icons.mdiRadioboxBlank }}
                </v-icon>
                <v-icon
                  v-else
                  color="warning"
                >
                  {{ icons.mdiCloseCircleOutline }}
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mdiRadioboxBlank, mdiCloseCircleOutline } from '@mdi/js'
import { useFilterStore } from '@/stores/FilterStore'
import { viewsList } from '@/components/filters/components'

export default {
    name: 'FilterOptionView',
    setup() {
        const filterStore = useFilterStore()

        return {
            filterStore,
            viewsList,
        }
    },
    data() {
    // Initialize localActiveView from filterStore
        return {
            localActiveView: this.filterStore.viewFilter.index,
            icons: {
                mdiRadioboxBlank,
                mdiCloseCircleOutline,
            },
        }
    },
    watch: {
        localActiveView: {
            handler(newIndex) {
                const newView = this.viewsList[newIndex] == null ? {} : { ...this.viewsList[newIndex], index: newIndex }
                this.filterStore.setViewFilter(newView)
            },
            deep: true,
        },
    },
}
</script>

<style scoped>
.my-list {
  padding: 0 !important;
}
</style>
