const routeConfig = {
    '/home': {
        FilterOptionStatus: false,
        FilterOptionView: false,
        FilterOptionThreshold: false,
        FilterOptionMarketShare: false,
        MoleculeSelect: true,
        PharmacySelect: true,
        QuickDateSelect: true,
        calls: [
            (dataStore, pharmacyListStore) => dataStore.setDataObject(['MfrMarketSharePieChartWatchList'], {}),
            (dataStore, pharmacyListStore) => dataStore.setDataObject(['monthlyStackedSalesByMfr'], {}),
            (dataStore, pharmacyListStore) => dataStore.setDataObject(['monthlyStackedSalesByMfrTar'], {}),
            (dataStore, pharmacyListStore) => pharmacyListStore.setPharmacyList(),
        ],
        filter: filterStore => filterStore.getFilterQuery,
        breadcrumbs: [],
    },
    '/pharmacy/pharmacy-molecule-list': {
        FilterOptionStatus: false,
        FilterOptionView: true,
        FilterOptionThreshold: true,
        FilterOptionMarketShare: true,
        MoleculeSelect: false,
        PharmacySelect: true,
        QuickDateSelect: true,
        calls: [
            dataStore => dataStore.setDataObject(['MfrMarketSharePieChartWatchList'], {}),
            dataStore => dataStore.setDataObject(['monthlyStackedSalesByMfr'], {}),
            dataStore => dataStore.setDataObject(['monthlyStackedSalesByMfrTar'], {}),
            (dataStore, pharmacyListStore) => dataStore.setPharmacyMoleculeList(),
            dataStore => dataStore.filterByTarget(),
        ],
        filter: filterStore => filterStore.getFilterQuery,
        breadcrumbs: [{ text: 'Pharmacy Molecule List', to: '/pharmacy/pharmacy-molecule-list' }],
    },
    '/pharmacy/molecule-dashboard': {
        FilterOptionStatus: false,
        FilterOptionView: false,
        FilterOptionThreshold: false,
        FilterOptionMarketShare: false,
        MoleculeSelect: true,
        PharmacySelect: true,
        QuickDateSelect: true,
        calls: [
            dataStore => dataStore.setMoleculeSnapshot(),
            dataStore => dataStore.setDataObject(['MfrMarketSharePieChartWatchList'], {}),
        ],
        filter: filterStore => filterStore.getFilterQuery,
        breadcrumbs: [
            { text: 'Pharmacy Molecule List', to: '/pharmacy/pharmacy-molecule-list' },
            { text: 'Molecule Dashboard', to: '/pharmacy/molecule-dashboard' },
        ],
    },
    '/iqreport/sales-market-share-analysis': {
        FilterOptionStatus: false,
        FilterOptionView: false,
        FilterOptionThreshold: false,
        FilterOptionMarketShare: false,
        MoleculeSelect: false,
        PharmacySelect: false,
        QuickDateSelect: true,
        calls: [],
        filter: filterStore => filterStore.getFilterQuery,
        breadcrumbs: [{ text: 'Sales & Market Share Analysis', to: '/iqreport/sales-market-share-analysis' }],
    },
}

export default routeConfig
