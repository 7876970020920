import axios from 'axios'
import config from '@/api/config'
import { getInstance } from '@/auth/authWrapper'
import { useFilterStore } from '@/stores/FilterStore'
import { queryRules } from '@/assets/lists'

const getData = async (initialQuery, body) => {
    const authService = getInstance()

    if (!authService.isAuthenticated) {
        console.log('User is not authenticated.')

        authService.loginWithRedirect({ appState: { targetUrl: window.location.pathname } })

        return
    }

    try {
        const filterStoreQuery = useFilterStore().getFilterQuery
        let query = initialQuery
        let requestBody = { ...body, ...filterStoreQuery }

        queryRules.forEach(rule => {
            if (query === rule.query) {
                const filterStoreQueryUpdate = {}

                Object.keys(rule.filterStoreQueryUpdate).forEach(key => {
                    filterStoreQueryUpdate[key] = rule.filterStoreQueryUpdate[key]()
                })

                requestBody = filterStoreQueryUpdate
                query = rule.newQuery
            }
        })

        // Get Auth0 instance and token
        const instance = getInstance()
        if (!instance) {
            throw new Error('Auth instance is not available')
        }

        const token = await instance.getTokenSilently()
        if (!token) {
            throw new Error('Could not fetch the token')
        }

        // Make the axios POST request
        const res = await axios.post(`${config.api}/genDash/?agg=${query}`, requestBody, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })

        return res.data
    } catch (e) {
        console.error(e)

        // Propagating the error so that it can be caught and handled by the caller
        throw e
    }
}

const getDataNoStore = async (query, body) => {
    try {
        const res = await axios.post(
      `${config.api}/genDash/?agg=${query}`,
      {
          ...body,
      },
      {
          headers: {
              Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
          },
      },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getData2 = async (query, body) => {
    try {
        const res = await axios.get(`${config.api}/genDash/?agg=${query}`, {
            params: {
                ...body,
                ...useFilterStore().getFilterQuery,
            },
            headers: {
                Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getDataSync = async (query, body) => {
    try {
        const res = await axios.post(
      `${config.api}/genDash/?agg=${query}`,
      {
          ...body,
          ...(useFilterStore().getFilterQuery
          && Object.keys(useFilterStore().getFilterQuery).reduce((acc, key) => {
              if (key !== 'RxDate') {
                  acc[key] = useFilterStore().getFilterQuery[key]
              }

              return acc
          }, {})),
      },
      {
          headers: {
              Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
          },
      },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const setPharmacyRatesData = async body => {
    try {
        const res = await axios.post(`${config.api}/setPharmacyRatesData`, body, {
            headers: {
                Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getPharmacyRatesData = async body => {
    try {
        const res = await axios.post(`${config.api}/getPharmacyRatesData`, body, {
            headers: {
                Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getSalesMarketShareAnalysis = async body => {
    try {
        const res = await axios.get(`${config.api}/SalesMarketShareAnalysis`, {
            // const res = await axios.get(`${config.api}/SalesMarketShareAnalysis`, body, {

            headers: {
                Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getAiReport = async initialQuery => {
    const authService = getInstance()

    if (!authService.isAuthenticated) {
        console.log('User is not authenticated.')

        return
    }

    try {
        const filterStoreQuery = useFilterStore().getFilterQuery
        let query = initialQuery
        let requestBody = { ...filterStoreQuery }

        queryRules.forEach(rule => {
            if (query === rule.query) {
                const filterStoreQueryUpdate = {}

                Object.keys(rule.filterStoreQueryUpdate).forEach(key => {
                    filterStoreQueryUpdate[key] = rule.filterStoreQueryUpdate[key]()
                })

                requestBody = filterStoreQueryUpdate
                query = rule.newQuery
            }
        })

        // Get Auth0 instance and token
        const instance = getInstance()
        if (!instance) {
            throw new Error('Auth instance is not available')
        }

        const token = await instance.getTokenSilently()
        if (!token) {
            throw new Error('Could not fetch the token')
        }

        // Make the axios POST request
        const res = await axios.post(`${config.api}/aiReportGet`, requestBody, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })

        return [res.data]
    } catch (e) {
        console.error(e)

        // Propagating the error so that it can be caught and handled by the caller
        throw e
    }
}

// const getAiReport = async body => {
//     console.log('🚀 ~ file: api.js:172 ~ body:', body)
//     try {
//         const res = await axios.post(`${config.api}/aiReportGet`, body, {
//             headers: {
//                 Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
//             },
//         })

//         return [res.data]
//     } catch (e) {
//         throw new Error(e)
//     }
// }

export default {
    getDataNoStore,
    getData,
    getData2,
    getDataSync,
    setPharmacyRatesData,
    getPharmacyRatesData,
    getSalesMarketShareAnalysis,
    getAiReport,
}
