import routeConfig from '@/assets/configs/routeConfig'

// eslint-disable-next-line import/prefer-default-export
export async function checkAndExecuteDataStoreCalls(
    route,
    dataStore,
    filterStore,
    pharmacyListStore,
    ignoreFilterCheck = false,
) {
    let routeData = routeConfig[route]

    // Check if routeData exists, if not, set it to '/home' route data
    if (!routeData) {
        routeData = routeConfig['/home']
    }

    // Check if localStorage 'filter' exists, if not, set it to '/home' route data filter
    if (!localStorage.getItem('filter')) {
        if (routeData.filter) {
            localStorage.setItem('filter', JSON.stringify(routeData.filter(filterStore)))
        }
    }
    if (ignoreFilterCheck || typeof routeData.filter !== 'function') {
        const calls = routeData.calls
            .filter(call => typeof call === 'function')
            .map(call => call(dataStore, pharmacyListStore))
        await Promise.all(calls)
    } else if (route === '/pharmacy/molecule-dashboard') {
        const calls = routeData.calls
            .filter(call => typeof call === 'function')
            .map(call => call(dataStore, pharmacyListStore))
        await Promise.all(calls)
    } else {
        const currentFilter = routeData.filter(filterStore)
        if (JSON.stringify(currentFilter) !== localStorage.getItem('filter')) {
            const calls = routeData.calls
                .filter(call => typeof call === 'function')
                .map(call => call(dataStore, pharmacyListStore))
            await Promise.all(calls)
            localStorage.setItem('filter', JSON.stringify(currentFilter))
        }
    }
}
