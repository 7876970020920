import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

const themeConfig = {
    app: {
        name: '<span class="tw-text-blue-500 tw-text-base">PHOX</span> <span class="tw-text-green-500 tw-text-base">IQ<span></span>',
        logo: require('@/assets/images/phox/logo.png'),
        isDark: false,
        isRtl: false,
        contentLayoutNav: 'horizontal', // vertical, horizontal
        routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
        // ! `semi-dark` isn't available for horizontal nav menu
        skinVariant: 'default', // default, bordered, semi-dark
        contentWidth: 'full',
    },
    menu: {
        isMenuHidden: false,
        isVerticalNavMini: false,
        verticalMenuAccordion: true,
        groupChildIcon: mdiCheckboxBlankCircleOutline,
        horizontalNavMenuGroupOpenOnHover: true,
    },
    appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
        type: 'fixed', // fixed, static, hidden
        isBlurred: true,
    },
    footer: {
        type: 'static', // fixed, static, hidden
    },
    themes: {
        light: {
            primary: '#2084f7',
            accent: '#0d6efd',
            secondary: '#152451',
            success: '#56CA00',
            info: '#16B1FF',
            warning: '#FFB400',
            error: '#FF4C51',
            pglightblue: '#4a6d88',
            white: '#ffffff',
            grey: '#8093ba',
            orange: '#e3a436',
            pgfaintblue: '#88a2bc',
            fieldBg: '#fcffb2',
            YellowGreenCrayola: '#d9ed92',
            YellowGreenCrayolas: '#b5e48c',
            GrannySmithApple: '#99d98c',
            OceanGreen: '#76c893',
            Keppel: '#52b69a',
            ViridianGreen: '#34a0a4',
            BlueMunsell: '#168aad',
            CGBlue: '#1a759f',
            LapisLazuli: '#1e6091',
            YaleBlue: '#184e77',
            cardbg: '#EAEAEA',
            pgdarkfield: '#1f2937',
            radioHighlight: '#ffff75',
        },
        dark: {
            primary: '#326caf',
            accent: '#0d6efd',
            secondary: '#152451',
            success: '#a3c18d',
            info: '#16B1FF',
            warning: '#ac8527',
            error: '#de8183',
            fieldBg: '#faf6f0',
            cardbg: '#121212',
            pgdarkfield: '#1f2937',
            radioHighlight: '#3700B3',
            grey: '#d6e8f1',
        },
    },
}

export default themeConfig
