import { useFilterStore } from '@/stores/FilterStore'
import { getDateRange } from '@/util/functions'
import { getInstance } from '@/auth/authWrapper'

const queryRules = [
    {
        query: 'monthlyStackedSalesByMfrTar',
        newQuery: 'monthlyStackedSalesByMfr',
        filterStoreQueryUpdate: {
            mfr: () => getInstance().user['https://api.pharmaguide.ca/claims/app_metadata'].mfr,
            RxDate: () => getDateRange('Last 12 Full Months'),
            Accreditation_Number: () => useFilterStore().getFilterQuery.Accreditation_Number,
            genericProductNumber: () => useFilterStore().getFilterQuery.genericProductNumber,
        },
    },

    // Add more rules here as needed...
]
export default queryRules
